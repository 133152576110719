import { Fragment } from "react";
import Paper from "@mui/material/Paper"
import List from "@mui/material/List";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from '@mui/material/Divider';
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
/**
 * Icons
 */
import TerminalTwoToneIcon from '@mui/icons-material/TerminalTwoTone';
import CottageTwoToneIcon from '@mui/icons-material/CottageTwoTone';
import SportsEsportsTwoToneIcon from '@mui/icons-material/SportsEsportsTwoTone';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
/**
 * AT2
 */
import { IMenuItems } from "../types/types";
import { useAppSelector } from "../hooks/hooks";

const MainDrawer = () => {
	const {windowHeight, config} = useAppSelector((state) => state.root);

	
	/** 
	 * Configuration for the menu items in the Main Drawer
	 * 
	 * path: 		react router path
	 * name: 		display name within the drawer
	 * icon: 		the Material UI Icon component
	 * divider: 	true to include divider line after menu
	 */
	const menuItems: Array<IMenuItems> = [
		{
			path: "/",
			name: "Home",
			icon: <CottageTwoToneIcon />,
			divider: false
		},
		{
			path: "command-processor",
			name: "Command Processor",
			icon: <TerminalTwoToneIcon />,
			divider: true
		},
		{
			path: "cli",
			name: "Command & Prototype",
			icon: <WysiwygIcon />,
			divider: true
		},
		{
			path: "phaser",
			name: "Phaser Test Bed",
			icon: <SportsEsportsTwoToneIcon />,
			divider: false
		},		
	];
	/** 
	 * MUI components styled using emotion
	 */
	const StyledListItemText = styled(ListItemText)(() => ({
		fontSize: "0.75rem"
	}));

	const StyledListItemButton = styled(ListItemButton)(() => ({
		paddingBottom: "2px",
		paddingTop: "2px"		
	}));

	const StyledDivider = styled(Divider)(() => ({
		marginBottom: "6px",
		marginTop: "6px"	
	}))
	/**
	 * Link is not styled using emotion because it is a react-router-dom component
	*/
	const drawerStyle = {
		link: {
			textDecoration: "none",
			color: "inherit"
		}
	}

	return (
        <Paper 
            elevation={2}
            style={{
                height: windowHeight - config.toolbarHeight
            }}
        >
            <List>
            {
                menuItems.map((item: IMenuItems) => {
                    return (
                        <Fragment key={item.path}>
                            <Link to={item.path} style={drawerStyle.link} >
                                <StyledListItemButton>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <StyledListItemText primary={item.name}/>
                                </StyledListItemButton>
                            </Link>
                            {
                                item.divider ? <StyledDivider /> : null
                            }
                        </Fragment>	
                    )
                })
            }
            </List>
        </Paper>
	);
}

export default MainDrawer;
