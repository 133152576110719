import { alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import { useRef, KeyboardEvent } from "react";
import { setSearchTerm, setLastEvaluatedKey } from "../../slices/rootSlice";

import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {InputAdornment, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles"

const TopToolbarSearch = () => {
	const dispatch = useAppDispatch();
	const theme = useTheme();

	const {searchTerm} = useAppSelector((state) => state.root);

	const inputRef = useRef<HTMLInputElement>(null);
	
	const handleSearch = () => {
		// removing focus
		inputRef.current?.blur(); 
		
		dispatch(setLastEvaluatedKey(undefined));
		dispatch(setSearchTerm(inputRef.current?.value));
	}

	const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
		switch (event.key) {
			case "Enter":
				handleSearch();
				break;
		}
	}

	const handleClearSearch = () => {
		inputRef.current!.value = "";
		dispatch(setSearchTerm(undefined));

		handleSearch();
	}

	const textFieldStyle = {
		backgroundColor: alpha(theme.palette.common.white, searchTerm ? 0.5 : 0.1 ),
		border: searchTerm ? "solid" : undefined,
		borderRadius: "5px"
	}

	return (
		<TextField
			style={textFieldStyle}
			size="small"
			placeholder= "Search..."
			inputRef={inputRef}
			inputProps={{ style: { textTransform: "lowercase" } }}
			InputProps={{
				startAdornment: <InputAdornment position="start" sx={{ cursor: 'default'}}>
					<SearchIcon onClick={handleSearch}/>
				</InputAdornment>,
				endAdornment: <InputAdornment position="end" sx={{ cursor: 'default'}}>
					{searchTerm && <ClearIcon onClick={handleClearSearch}/>}
				</InputAdornment>
			}}
			onKeyDown={handleKeyPress}
		/>
	)
}

export default TopToolbarSearch;
