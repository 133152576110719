import { Box, Button, Divider, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow, Typography } from "@mui/material"
import { CSSProperties, ReactElement } from "react";
import { IMuiOutputProps, IButtonControl, ITableControl, TableRows, ILinkControl, TableColumns, IControlItem, ICodeBlockControl, IColorControl, IImageControl, IListControl, IFieldEntry, FieldsControl} from "../../types/common/muiOutput";
import CircleIcon from '@mui/icons-material/Circle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ControlList } from "../../types/enums";
import { grey } from "@mui/material/colors"
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useTheme } from "@mui/material/styles"
import { setMessage, setOpen } from "../../slices/snackbarSlice";

export const MuiOutput = ({ commandControls }: IMuiOutputProps ) => {
	const dispatch = useAppDispatch();

	const isDark = useAppSelector((state) => state.colorMode.isDark);
	const theme = useTheme();
	let muiOutputs: Array<ReactElement> = [];
	
	const boxStyle: CSSProperties = {
		display: "flex",
		flexDirection: "column",
		paddingLeft: "10px"
	}

	const codeBlockStyle: CSSProperties = {
		padding: "4px",
		margin: 0,
		borderRadius: "4px",
		border: "solid 1px gray",
		overflow:"auto"
	}

	const copyIconStyle: CSSProperties = {
		position: "absolute",
		right: "20px",
		marginTop: "5px"
	}

	const controlStyle: CSSProperties = {
		padding: "3px 0px"
	}

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
		  backgroundColor: theme.palette.common.black,
		  color: theme.palette.common.white,
		},
		[`&.${tableCellClasses.body}`]: {
		  fontSize: 14,
		},
	  }));
	  
	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		}
	}));

	const handleControlCopy = (data: string) => {
		navigator.clipboard.writeText(data);

		dispatch(setMessage("Copied to clipboard..."));
		dispatch(setOpen(true));
	}

	commandControls.forEach((controlItem: IControlItem, _controlIndex: number) => {
		let control: ReactElement = (<></>);
		let config: any;
		
		switch (controlItem.control) {
			case ControlList.Fields:
				config = controlItem.data as FieldsControl;

				control = (<List>
					<Grid container spacing={3}>
						{config.map((fieldEntry: IFieldEntry, fieldIndex: number) => {
							return (
								<Grid item xs key={"field-"+fieldIndex}>
									<Paper sx={{backgroundColor: (isDark) ? grey.A700 : grey.A100}} style={{display: "flex", flexDirection: "column",}} >
										<Box sx={{ display: "inline-block", p: 1, fontWeight: "bold", color: theme.palette.text.disabled }}>{fieldEntry.title}</Box>
										<Box sx={{ display: "inline-block", p: 1 }}>{fieldEntry.body}</Box>
									</Paper>
								</Grid>
							)
						})}
					</Grid>
				</List>);
				break;
			case ControlList.Table:
				config = controlItem.data as ITableControl;
				control = (<>
					<ContentCopyIcon style={copyIconStyle} onClick={() => {handleControlCopy(JSON.stringify(config))}}/>
					<Table size="small" aria-label="a dense table">
						{/* Build header */}
						<TableHead>
							<TableRow>
								{config.header.map((headerName: string, headerIndex: number) => {
									return (
										<StyledTableCell key={"tableHeader-" + headerIndex} align="left">
											{headerName}
										</StyledTableCell>
									)
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{config.rows.map((rowData: TableRows, rowIndex: number) => {
								return(
									<StyledTableRow key={"rowData" + rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										{rowData.map((cellData: TableColumns, cellIndex: number) => {
											if(cellIndex === 0){
												return (
													<StyledTableCell key={"cell-" + rowIndex + "-" + cellIndex} component="th" scope="row" align="left">
														{cellData}
													</StyledTableCell>
												)
											}
											return (
												<StyledTableCell key={"cell-" + rowIndex + "-" + cellIndex} align="left">
													{cellData}
												</StyledTableCell>
											)
										})}
									</StyledTableRow>
								)
							})}
						</TableBody>
					</Table>
				</>);
				break;
			case ControlList.OrderedList:
				config = controlItem.data as IListControl;
				control = (<>
					<ContentCopyIcon style={copyIconStyle} onClick={() => {handleControlCopy(config.items.join(", "))}}/>
					<List>
						{config.items.map((item: string, listIndex: number) => {
							return (
								<ListItem key={"listItem-" + listIndex} disableGutters style={{padding: "0px 0px"}}>
									<ListItemText>
										<Typography
											sx={{ display: "inline", marginRight: "10px" }}
										>
											{listIndex + 1}.
										</Typography>
										{item}
									</ListItemText>
								</ListItem>
							)
						})}
					</List>
				</>);
				break;
			case ControlList.UnorderedList:
				config = controlItem.data as IListControl;
				control = (<>
					<ContentCopyIcon style={copyIconStyle} onClick={() => {handleControlCopy(config.items.join(", "))}}/>
					<List>
						{config.items.map((item: string, listIndex: number) => {
							return (
								<ListItem key={"listItem-" + listIndex} disableGutters style={{padding: "0px 0px"}}>
									<ListItemIcon style={{minWidth: "22px", fontSize: "8px"}}>
										<CircleIcon fontSize="inherit"/>
									</ListItemIcon>
									<ListItemText>
										{item} 
									</ListItemText>
								</ListItem>
							)
						})}
					</List>
				</>);
				break;
			case ControlList.Hr:
				control = <Divider/>;
				break;
			case ControlList.Title:
				control = <h1>{controlItem.data}</h1>;
				break;
			case ControlList.Color:
				config = controlItem.data as IColorControl;
				boxStyle.borderLeftColor = config.color;
				boxStyle.borderLeftWidth = "4px";
				boxStyle.borderLeftStyle = "solid";
				break;
			case ControlList.Br:
				control = <br/>;
				break;
			case ControlList.Image:
				config = controlItem.data as IImageControl;
				control = <img 
					src={config.imageUrl} 
					alt={""}
					width={config.width}
					height={config.height}
				/>
				break;
			case ControlList.Link:
				config = controlItem.data as ILinkControl;
				control = <>
					<ContentCopyIcon style={copyIconStyle} onClick={() => {handleControlCopy(config.linkUrl)}}/>
					<Link 
						href={config.linkUrl}
						target="_blank"
						variant="body2"
						color="primary"
						fontSize={"18px"}
					>{config.title}</Link>
				</>
				break;
			case ControlList.Button:
				config = controlItem.data as IButtonControl;
				control = (<>
					<ContentCopyIcon style={copyIconStyle} onClick={() => {handleControlCopy(config.linkUrl)}}/>
					<Button
						variant="contained" 
						target="_blank" 
						href={config.linkUrl}
					>{config.title}</Button>
				</>);
				break;
			case ControlList.CodeBlock:
				config = controlItem.data as ICodeBlockControl;
				control = (<>
					<ContentCopyIcon style={copyIconStyle} onClick={() => {handleControlCopy(config.code)}}/>
					<pre style={codeBlockStyle}>
						<code>{config.code}</code>
					</pre>
				</>);
				break;
			case ControlList.BlockQuote:
				control = (<>
					<ContentCopyIcon style={copyIconStyle} onClick={() => {handleControlCopy(controlItem.data)}}/>
					<blockquote style={{marginInlineStart: "0px", marginInlineEnd: "0px"}}>
						{controlItem.data}
					</blockquote>
				</>);
				break;
		}

		muiOutputs.push((
			<Box key={"muiOutput-" + _controlIndex} style={controlStyle}>
				{control}
			</Box>
		));

		return controlItem;
	});

	return (
		<Box sx={{ marginRight: "20px" }} style={boxStyle}>
			{muiOutputs}
		</Box>
	)
}
