
interface IConfigObject {
	SENTRY_DSN: { 
		[index: string]: string
	}
}
/**
 * AT2X Application Configuration Object
 * 
 * | property | description |
 * | ----- | ----- |
 * | SENTRY_DSN | Sentry DSN for each deployment stage |
 */
const config: IConfigObject = {
	SENTRY_DSN: {
		"dev": "https://dca62f7a80ad4d9bb1dda02961ba3986@o1103530.ingest.sentry.io/4504005951488000",
		"prod": "https://1d37bd971e6a4437baab094b6f939821@o1103530.ingest.sentry.io/4503976740192256"
	}	
}

export default config;
