import { CSSProperties, useRef, useState, useEffect } from "react";
import { CommandProcessorCard } from "../components/command/CommandProcessorCard";
import { useAppSelector, useAppDispatch } from "../hooks/hooks";
import { BasicSnackbar } from "../components/common/BasicSnackbar";
import { Button, CircularProgress, Container, Typography } from "@mui/material";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { setLastEvaluatedKey, setSearchTerm } from "../slices/rootSlice";
import { prependCommand, setShouldDisplayJumpToBottom, setIsFetching, setCommands } from "../slices/commandProcessorSlice";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ICommandReadResponse, IDisplay } from "../types/common/command";
import { CommandProcessorInput } from "../components/command/CommandProcessorInput";
import { IDynamoDBResponse } from "../types/types";

const CommandProcessorRoute = () => {
	const dispatch = useAppDispatch();
	const {windowHeight, config, lastEvaluatedKey, searchTerm} = useAppSelector((state) => state.root);
	const {commands, shouldDisplayJumpToBottom, isFetching } = useAppSelector((state) => state.command);

	const { user } = useAuthenticator((context) => [context.user]);

	const [inputHeight, setInputHeight] = useState(0);
	const [errorMessage, setErrorMessage] = useState(undefined);
	const [isEndOfHistory, setIsEndOfHistory] = useState(false);
	const [oldScrollHeight, setOldScrollHeight] = useState(-1);

	const inputRef = useRef<HTMLDivElement>(null);
	const outputRef = useRef<HTMLDivElement>(null);

	const handleFetchError = (error: any) => {
		setErrorMessage(error.message);
		dispatch(setIsFetching(false));
	}

	const newFetch = () => {
		dispatch(setCommands([]));
		dispatch(setLastEvaluatedKey(undefined));
		setIsEndOfHistory(false);
		setOldScrollHeight(-1);

		doFetch();
	}

	const doFetch = () => {
		const url = new URL(`${process.env.REACT_APP_API_URL}/commands/${user.attributes?.sub}`);
		if(lastEvaluatedKey){
			const encodedLastEvalKey = btoa(JSON.stringify(lastEvaluatedKey));
			url.searchParams.append("page", encodedLastEvalKey);
		}
		if (searchTerm) {
			url.searchParams.append("search", searchTerm);
		}

		dispatch(setIsFetching(true));
		fetch(url.href)
			.then(response => response.json())
			.then(handleFetchResponse)
			.catch(error => handleFetchError(error));
	}
	
	// This will run the effect after the dependancy changes
	useEffect(() => {
		setInputHeight(inputRef.current!.clientHeight);
	}, [inputRef]);

	// This will run after the search term changes
	useEffect( newFetch, [searchTerm]);

	// This will run the effect after every render, hence no dependancy
	useEffect(() => {
		if(!shouldDisplayJumpToBottom){
			outputRef.current?.scrollTo(0, outputRef.current.scrollHeight);
		}
	});

	// This will run the effect on component unmount, here we can do some cleanup
	useEffect(() => {
		return () => {
			dispatch(setCommands([]));
			dispatch(setLastEvaluatedKey(undefined));
			dispatch(setSearchTerm(undefined));
		}
	}, []);
	
	const commandContainerStyle: CSSProperties = {
		position: "relative",
		maxWidth: "100%",
		height: windowHeight - config.toolbarHeight,
		display: "flex",
		justifyContent: "center"
	};
	
	const commandOutputStyle: CSSProperties = {
		position: "absolute",
		top: 0,
		left: 0,
		// TODO: Change -5 to the bottom pos of the input window
		height: windowHeight - config.toolbarHeight - inputHeight - 5,
		width: "100%",
		overflow: "auto"
	};

	const jumpToBottomStyle: CSSProperties = {
		position: "absolute",
		bottom: 80
	};

	const commandInputStyle: CSSProperties = { 
		width: "100%",
		position: "absolute",
		bottom: 4,
		marginLeft: "10px"
	};

	const typographyStyle: CSSProperties = { 
		margin: "15px"	
	};

	const handleResize = () => {
		// Update input height state after input height changes
		requestAnimationFrame(() => {
			setInputHeight(inputRef.current!.clientHeight);
		});
	} 

	const handleScroll = (event: any) => {
		if(event.target.scrollTop === 0 && !isFetching && !isEndOfHistory){
			doFetch();
		} else if (event.target.offsetHeight + event.target.scrollTop + windowHeight < event.target.scrollHeight) {
			dispatch(setShouldDisplayJumpToBottom(true));
		} else if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight){
			dispatch(setShouldDisplayJumpToBottom(false));
		}
	}

	const handleJumpToBottom = () => {
		dispatch(setShouldDisplayJumpToBottom(false));
		outputRef.current?.scrollTo(0, outputRef.current.scrollHeight);
	}

	const handleFetchResponse = (response: IDynamoDBResponse<ICommandReadResponse>) => {
		setErrorMessage(undefined);
		dispatch(setIsFetching(false));

		// Set the scroll position to the location of the previous results
		requestAnimationFrame(() => {
			if(oldScrollHeight === -1){
				setOldScrollHeight(outputRef.current?.scrollHeight as number);
				handleJumpToBottom();
			} else {
				let deltaScrollHeight = outputRef.current?.scrollHeight as number - oldScrollHeight;
				outputRef.current?.scrollTo(0, deltaScrollHeight);
				setOldScrollHeight(outputRef.current?.scrollHeight as number);
			}
		});

		// The absence of LastEvaluatedKey indicates that the last page of results
		setIsEndOfHistory(response.lastEvaluatedKey === undefined);

		dispatch(setLastEvaluatedKey(response.lastEvaluatedKey));
		response.items.forEach((result: ICommandReadResponse) => {
			dispatch(prependCommand({
				user: (user.attributes?.email as string).split("@")[0],
				created: result.created,
				command: result.command,
				params: (result.params) ? JSON.parse(result.params) : [],
				output: (result.controls) ? JSON.parse(result.controls) : []
			}));
		});
	}

	const displayContent = () => {
		if(errorMessage !== undefined && !isFetching){
			return <Typography variant="h4" style={typographyStyle}> {errorMessage} </Typography>
		} else if (commands?.length === 0 && !isFetching) {
			return <Typography variant="h4" style={typographyStyle}> No Command History </Typography>
		} else {
			return <>
				{isEndOfHistory && <Typography variant="h4" style={typographyStyle}> That's all folks! </Typography>}
				{commands?.map(function(command: IDisplay, index: number) {
					if(!shouldDisplayJumpToBottom) {
						outputRef.current?.scrollTo(0, outputRef.current.scrollHeight);
					}
					return <CommandProcessorCard command={command} key={index + "-command-processor-card"} />
				})}
			</>
		}
	}

	return (
		<Container id="command-container" style={commandContainerStyle}>
			<div 
				id="command-output" 
				ref={outputRef} 
				style={commandOutputStyle}
				onScroll={handleScroll}
			>
				{isFetching && <CircularProgress style={{ margin: "15px", position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}/>}
				{displayContent()}
			</div>

			{shouldDisplayJumpToBottom && <Button 
				variant="contained" 
				endIcon={<KeyboardDoubleArrowDownIcon />}
				style={jumpToBottomStyle}
				onClick={handleJumpToBottom}
			>
				Jump to Bottom
			</Button>}

			<div 
				id="command-input"
				ref={inputRef}
				style={commandInputStyle}
				onChange={handleResize}
			>
				<CommandProcessorInput key={"command-processor-input"} />
			</div>

			<BasicSnackbar/>
		</Container>
	);
}

export default CommandProcessorRoute;
