import Typography from "@mui/material/Typography";

const TopToolbarIcon = () => {
	const topToolbarIconStyle = {
		logo: {
			width: 35,
			height: 35
		}
	}

	return (
		<Typography
			variant="h6"
			noWrap
			component="div"
			textAlign="left"
			sx={{ flexGrow: 1, display: { xs: "none", sm: "block" }, paddingTop: 1 }}
		>
			<img
				src="images/logo/anywhere-teacher-check.svg"
				width={topToolbarIconStyle.logo.width}
				height={topToolbarIconStyle.logo.height}
				alt="Anywhere Teacher Logo with check mark"
			/>
			
		</Typography>
	)
}

export default TopToolbarIcon;
