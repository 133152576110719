import { Box, TextField } from "@mui/material"
import { useState, useRef, KeyboardEvent } from "react";
import SendIcon from '@mui/icons-material/Send';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { appendCommand } from "../../slices/commandProcessorSlice";
import { useAppSelector, useAppDispatch } from "../../hooks/hooks";
import { setShouldDisplayJumpToBottom } from "../../slices/commandProcessorSlice";
import Commands from "../../classes/Commands"
import { useAuthenticator } from "@aws-amplify/ui-react";

export const CommandProcessorInput = () => {
	const dispatch = useAppDispatch();
	const inputRef = useRef<HTMLInputElement>(null);
	const isFetching = useAppSelector((state) => state.command.isFetching);
	const commandHistoryStorageKey: string = "commandProcessorHistory";

	const { user } = useAuthenticator((context) => [context.user]);

	const [isMultiline, setIsMultiline] = useState(false);
	const [commandField, setCommandField] = useState('');
	const [commandHistoryIndex, setCommandHistoryIndex] = useState(-1);

	let commandHistory: Array<string> = (localStorage.getItem(commandHistoryStorageKey) === null) ? [] : JSON.parse(localStorage.getItem(commandHistoryStorageKey) || "{[]}");

	const handleSend = () => {
        const processCommand = new Commands();

		let inputText = commandField.toLocaleLowerCase();
        const result = processCommand.process(user.attributes?.sub as string, inputText);

		dispatch(appendCommand({
			user: (user.attributes?.email as string).split("@")[0],
			created: Date.now(),
			command: result.command[0].toLocaleLowerCase(),
			params: result.command.slice(1).map(param => param.toLocaleLowerCase()),
			output: result.response
		}));

		// Save to local command history
		if(commandHistory[0] !== inputText){
			// New commands are added to the BEGINNING of the array. I.e. commandHistory[0] is the latest command
			commandHistory.unshift(inputText);
		}
		localStorage.setItem(commandHistoryStorageKey, JSON.stringify(commandHistory));
		setCommandHistoryIndex(-1);

		// Clear text field
		setCommandField("");

		dispatch(setShouldDisplayJumpToBottom(false));		
	}

	const handleMultilineInput = () => {
		setIsMultiline(!isMultiline);
	}

	const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
		// By nature multiline cannot support "send on enter" or "arrow key local history" because 
		// these controls are used to control the cursor in the multiline text field
		if(!isMultiline){
			switch (event.key) {
				case "Enter":
					handleSend();
					break;
				case "Up":
				case "ArrowUp":
					handleArrowKey("Up");
					break;
				case "Down":
				case "ArrowDown":
					handleArrowKey("Down");
					break;
			}
		}
	}

	const handleArrowKey = (key: "Up" | "Down") => {
		let newIndex: any = undefined;

		if( key === "Up" && commandHistory.length > 0 && commandHistoryIndex < commandHistory.length - 1){
			newIndex = commandHistoryIndex + 1;
		} else if( key === "Down" && commandHistoryIndex > -1){
			newIndex = commandHistoryIndex - 1;
		}

		if(newIndex !== undefined){
			requestAnimationFrame(() => {
				setCommandHistoryIndex(newIndex);

				let text = (newIndex === -1) ? "" : commandHistory[newIndex];
				setCommandField(text);
				inputRef.current!.setSelectionRange(text.length, text.length);
			});
		}
	}

	const handleInputChange = (event: any) => {
		setCommandField(event.target.value);
	}

	const boxProperties = {
		marginRight: "20px",
		display: "flex",
		alignItems: "center"
	}

	const inputProperties = {
		flexGrow: 1
	}

	const inputStyle = {
		width: "inherit",
		pointerEvent: "all"
	}

	const iconStyle = {
		marginRight: 4,
		marginLeft: 4,
		width: 30,
		height: 30
	}

	return (
		<Box sx={boxProperties} style={{width: "100%", paddingRight: "5px", minHeight: 50}}>
			
			<TextField 
				id="command-input"	
				style={inputStyle}
				sx={inputProperties}			
				placeholder={"Enter command..."}
				value={commandField}
				autoFocus={true}
				onKeyDown={handleKeyPress}
				onChange={handleInputChange}
				multiline={isMultiline}
          		maxRows={(isMultiline) ? 4 : 1}
				disabled = {isFetching}
				inputRef={inputRef}
			/>

			{isMultiline ? (
				<>
					<SendIcon style={iconStyle}	onClick={handleSend} />
					<RemoveCircleOutlineIcon style={iconStyle}	onClick={handleMultilineInput} />
				</>
			) : (
				<AddCircleIcon style={iconStyle} onClick={handleMultilineInput}	/>
			)}
			
		</Box>
	)
}