//import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useTheme, Theme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
/**
 * AT2
 */
import TopToolbarIcon from "./TopToolbarIcon";
import TopToolbarUser from "./TopToolbarUser";
import TopToolbarVersion from "./TopToolbarVersion";
import TopToolbarSearch from "./TopToolbarSearch";
import { toggleColorMode } from "../../slices/colorModeSlice";
import { useAppSelector, useAppDispatch } from "../../hooks/hooks";

const themedStyles = (theme: Theme) => {
	return {
		appbar: {
			zIndex: theme.zIndex.drawer + 1
		}
	}
}

const TopToolbar = (props: any) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const isDark = useAppSelector((state) => state.colorMode.isDark);
	const { toolbarHeight } = useAppSelector((state) => state.root.config);

	const StyledToolbar = styled(Toolbar)(() => ({
		minHeight: toolbarHeight
	}));

	const handleColorToggle = () => {
		dispatch(toggleColorMode())
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="fixed" sx={themedStyles(theme).appbar}>
				<StyledToolbar>
					<Box>
						<TopToolbarIcon />
					</Box>
					<Box>
						<TopToolbarVersion />
					</Box>
					<Box 
						display="flex"
						justifyContent="center" 
						sx={{flexGrow: 1}}
					>
						<TopToolbarSearch />
					</Box>
                    <Box>
						<TopToolbarUser />
					</Box>
					<Box
						sx={{
							width: 'auto',
							color: 'text.primary',
							borderRadius: 1,
							p: 1,
						}}
						>
						<IconButton sx={{ ml: 1 }} onClick={handleColorToggle} color="inherit">
							{isDark ? <Brightness7Icon /> : <Brightness4Icon />}
						</IconButton>
					</Box>
				</StyledToolbar>
			</AppBar>
		</Box>		
	)
}

export default TopToolbar;