import { useAppSelector, useAppDispatch } from '../../hooks/hooks';
import { forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { setOpen } from "../../slices/snackbarSlice"


export const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert( props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const BasicSnackbar = () => {
	const dispatch = useAppDispatch();
	const {isOpen, severity, message} = useAppSelector((state) => state.snackbar);

	const handleClose = () => {
		dispatch(setOpen(false));
	}

	return (
		<Snackbar 
			autoHideDuration={3000} 
			onClose={handleClose}
			open={isOpen}
		>
			<Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
				{message}
			</Alert>
		</Snackbar>
	);
}