/**
 * ## Command Processor  
 * 
 * Process commands for the Command & Prototype environment
 * 
 * @category Class
 * @module Command Processor
 */
import { ICommand, IControl, IControlItem } from "../types/types";
import CommandFactory from "./commands/CommandFactory";

interface ICommandsResponse {
    command: Array<string>;
    outputEngine: string;
    response: Array<IControlItem>;
}

class Commands {
    /**
     * ### Process Command
     * 
     * Receive a raw command string; parse and execute the command using the  
     * Command Factory.  
     *   
     * Return the output from the execution of the command.  
     *   
     * Command Response:  
     * ```
     * {
     *      command: the parsed results of the raw command string  
     *      outputEngine: what output type is expected  
     *      response: the generated output from the command and formatted according to the outputEngine  
     * }
     * ```
     *   
     * @param command       raw command string
     * @returns             command response
     */
    public process = (uuid: string, command: string): ICommandsResponse => {
		const factory = new CommandFactory();
        const splitCommand = command.replace(/\n/g, " ").split(" ");
        const factoryCommand = factory.getCommand(splitCommand);
        const result: IControl = factoryCommand.execute();

        const processorItem: ICommand = {
            uuid: uuid,
            created: Date.now(),
            output: JSON.stringify(result.items),
            command: splitCommand[0] as string,
            params: JSON.stringify(splitCommand.slice(1))
        }

        if(result.shouldSaveCommand){
            this.save(processorItem);
        }

        const response: ICommandsResponse = {
            command: splitCommand,
            outputEngine: factoryCommand.outputEngine,
            response: result.items
        }

        return response;
    }

    private save = (processorItem: ICommand) => {
        let requestOptions: RequestInit = {
            method: 'POST',
            body: JSON.stringify(processorItem),
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_API_URL}/command`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }
}

export default Commands;
