import Phaser from "phaser";

class PhaserFWScene extends Phaser.Scene {

	constructor(){
		super("PhaserFWScene");
	}

	preload = () => {
		this.load.image("icon", "images/logo/anywhere-teacher-check.svg");
	}

	create = () => {
		//const atLogo = this.add.image(400, 300, "icon");
		// this.tweens.add({
		// 	targets: atLogo,
		// 	x: 700,
		// 	duration: 2000,
		// 	ease: 'Power2',
		// 	yoyo: true,
		// 	delay: 1000
		// });
	};

	update = () => {

	}
}

export default PhaserFWScene;
