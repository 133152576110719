/**
 * #### Control Output
 * 
 * @param outputEngine  
 * AbstractCommand will use a simple factory pattern to instantiate the desired output
 * class for use by the command.  
 *   
 * | ID | Description |
 * | --- | --- |
 * | md | output support for CommonMark implementation of Markdown. |
 * | control (default) | output support for SZ control structure output. |
 *
 * @abstract
 * @class ControlOutput
 */
import { 
    ITableControl,
    IFieldEntry,
    FieldsControl,
    ILinkControl,
    IButtonControl,
    IControl,
    List,
    ICodeBlockControl,
    IColorControl,
    IImageControl,
    IListControl
} from "../types/types";

import { ControlList } from "../types/enums";

class ControlOutput {
    private _controls: IControl;

	constructor () {
        this._controls = {
            items: []
        }
	}

    private addControl = (control: ControlList, data?: any) => {
        this._controls.items.push({
            control: control,
            data: data ? data : {}
        })
    }

	public setTitle = (title: string): void => {
        this.addControl(ControlList.Title, title);
	}

    public setColor = (color: string): void => {
        const config: IColorControl = {
            color: color
        }
        this.addControl(ControlList.Color, config);
	}

	public addTable = (table: ITableControl): void => {
        this.addControl(ControlList.Table, table);
	}

	public addField = (title: string, body: string): void => {
        const config: IFieldEntry = {
            title: title,
            body: body
        }
        this.addControl(ControlList.Fields, [config]);
	}

	public addFields = (fields: FieldsControl): void => {
        this.addControl(ControlList.Fields, fields);
	}

	public addHR = (): void => {
		this.addControl(ControlList.Hr);
	}

	public addBR = (): void => {
		this.addControl(ControlList.Br);
	}

	public addOrderedList = (list: List): void => {
        const config: IListControl = {
            items: list
        }
        this.addControl(ControlList.OrderedList, config);
	}

    public addUnorderedList = (list: List): void => {
        const config: IListControl = {
            items: list
        }
        this.addControl(ControlList.UnorderedList, config);
	}

	public addImage = (imageUrl: string, width?: number, height?: number): void => {
        const config: IImageControl = {
            imageUrl: imageUrl,
            width: width,
            height: height
        }
        this.addControl(ControlList.Image, config);
	}

	public addLink = (text: string, linkUrl: string): void => {
        const config: ILinkControl = {
            title: text,
            linkUrl: linkUrl
        }
        this.addControl(ControlList.Link, config);
	}

    public addButton = (text: string, linkUrl: string): void => {
        const config: IButtonControl = {
            title: text,
            linkUrl: linkUrl
        }
        this.addControl(ControlList.Button, config);
	}

	public addCodeBlock = (code: string): void => {
        const config: ICodeBlockControl = {
            code: code
        }
        this.addControl(ControlList.CodeBlock, config);
	}

	public addBlockQuote = (quote: string) => {
        this.addControl(ControlList.BlockQuote, quote);
	}

	public generateControls = (_shouldSaveCommand: boolean = true) : IControl => {
        this._controls.shouldSaveCommand = _shouldSaveCommand;

		return this._controls;
	}

}

export default ControlOutput;
