import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AttributeValue } from "aws-lambda";
import { IRootState, IRootConfig } from "../types/types";

const initialState: IRootState = {
	config: { toolbarHeight: 64 },
	windowWidth: 0,
    windowHeight: 0,
	searchTerm: undefined,
	lastEvaluatedKey: undefined
}

const rootSlice = createSlice({
	name: "root",
	initialState,
	reducers: {
		setConfig: (state, action: PayloadAction<IRootConfig>) => {
			state.config = action.payload;
		},
		setWindowWidth: (state, action: PayloadAction<number>) => {
			state.windowWidth = action.payload;
		},
        setWindowHeight: (state, action: PayloadAction<number>) => {
			state.windowHeight = action.payload;
		},
		setSearchTerm: (state, action: PayloadAction<string | undefined>) => {
			state.searchTerm = action.payload;
		},
		setLastEvaluatedKey: (state, action: PayloadAction<Record<string, AttributeValue> | undefined>) => {
			state.lastEvaluatedKey = action.payload;
		},
	}
});

export const { setConfig, setWindowWidth, setWindowHeight, setSearchTerm, setLastEvaluatedKey } = rootSlice.actions;

export default rootSlice.reducer;
