/** 
 * Anywhere Teacher 2.0 Theme - Admin Light Theme
 */
const AT2ThemeLight = {
	typography: {
		fontSize: 12,
		subtitle2: {
			fontSize: 10
		}
	}
};

export default AT2ThemeLight;
