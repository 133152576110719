import { IAbout } from "../../types/types";
import AbstractCommand from "./AbstractCommand";

class TemplateCommand extends AbstractCommand {

    execute() {
		if (this.params[1] === "?") return this.help();

		this.output.setTitle("Template Command");
		this.output.addField("Field Heading", "Lorem ipsum dolor sit amet, consectetur adipiscing elite");

		const controlsObject = this.output.generateControls();
        return controlsObject;
    }

	help () {
		this.output.setTitle("Template Command");
		this.output.addField("", 
			`Used to template the command factory and can be used as a template for all new commands.
			
			No parameters supported.`
		);
        return this.output.generateControls(false);
	}

	about (): IAbout {
		return {
			name: "Template",
			description: "Template some stuff"
		}
	}
}

export default TemplateCommand;
