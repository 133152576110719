import { useAppDispatch } from '../../hooks/hooks'
import { Box, Grid, Paper } from "@mui/material"
import { ICommandProcessorCardProps } from "../../types/types"
import { setOpen, setMessage } from "../../slices/snackbarSlice"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CSSProperties, useEffect, useRef } from 'react';
import { MuiOutput } from './MuiOutput';

export const CommandProcessorCard = ({ command }: ICommandProcessorCardProps ) => {
	const dispatch = useAppDispatch();
	const paperRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const paperElement = paperRef.current;
		if(paperElement){
			paperElement.animate(
				[  
					{ opacity: 0 },
					{ opacity: 1 }
				],
				{
					easing: "ease-in-out",
					iterations: 1,
					duration: 1000
				}
			);
		}
	}, []);

	const boxStyle = {
		padding: "2px"
	}

	const copyIconStyle: CSSProperties = {
		position: "absolute",
		right: "20px",
		marginTop: "15px"
	}

	let commandDate = new Date(command.created);
	let dateStr = (commandDate.getMonth() + 1) + "/" + commandDate.getDate() + "/" + (commandDate.getFullYear().toString().slice(2));
	let timeStr = (commandDate.getHours()) + ":" + (commandDate.getMinutes() < 10 ? "0" + commandDate.getMinutes() : commandDate.getMinutes());

	const handleCopy = () => {
		navigator.clipboard.writeText([command.command, command.params].join(" "));

		dispatch(setMessage("Command copied..."));
		dispatch(setOpen(true));
	}

	return (
		<>
			<Box sx={{ marginLeft: "10px", marginRight: "10px" }} style={boxStyle}>
				<Grid container >
					<Grid 
						item sm={12} lg={1} sx={{ 
							textAlign: {sm:"left", lg:"right"},
							display: "flex",
							flexDirection: {sm:"row", lg:"column"}
						}}>
						<Box sx={{ p: 1 }}>{ dateStr }</Box>
						<Box sx={{ p: 1 }}>{ timeStr }</Box>
					</Grid>
					<Grid  item sm={12} lg={11}>
						<Paper sx={{ px: 1 }} ref={paperRef}>
							<Grid  container>
								<Grid  item xs={10}>
									<Box sx={{ display: "inline-block", p: 1, border: '1px dashed grey', borderRadius: "4px", marginTop: "4px" }}>{ command.command }</Box>
									<Box sx={{ display: "inline-block", p: 1 }}>{ command.params.join(" ") }</Box>
								</Grid>
								<Grid item xs={2} sx={{ textAlign: "right" }}>
									<Box sx={{ display: "inline-block", p: 1 }} style={{marginRight: "25px", marginTop: "4px"}} >{ command.user }</Box>
								</Grid>
								<ContentCopyIcon style={copyIconStyle} onClick={handleCopy} />
							</Grid>
							<Grid  container>
								<Grid item  style={{maxWidth:"100%"}}>
									<Box sx={{ p: 1 }}>
										<MuiOutput commandControls={command.output}/>
									</Box>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Box>
		</>
	)
}
