
import { CommandParameters } from "../../types/common/command";
import { IAbout } from "../../types/common/common";
import { FieldsControl, ITableControl, List, TableHeader, TableRows } from "../../types/common/muiOutput";
import AbstractCommand from "./AbstractCommand";

class AllControlsCommand extends AbstractCommand {

    constructor (params: CommandParameters, outputEngine: string = "control") {
        super(params, outputEngine);
    }

    execute() {
		if (this.params[1] === "?") return this.help();

		this.output.setTitle("All Controls Command");
        this.output.setColor("#12FFA1");

        const tableHeader: TableHeader = ["Column A", "Column B", "Column C", "Column D", "Column E"]
        const tableContent: TableRows = [
            ["this is a really really long row 1 A", "this is a really really long row 1 B", "this is a really really long row 1 C", "this is a really really long row 1 D", "this is a really really long row 1 E"],
            ["this is a really really long row 2 A", "this is a really really long row 2 B", "this is a really really long row 2 C", "this is a really really long row 2 D", "this is a really really long row 2 E"],
            ["this is a really really long row 3 A", "this is a really really long row 3 B", "this is a really really long row 3 C", "this is a really really long row 3 D", "this is a really really long row 3 E"],
            ["this is a really really long row 4 A", "this is a really really long row 4 B", "this is a really really long row 4 C", "this is a really really long row 4 D", "this is a really really long row 4 E"]
        ]
        const table: ITableControl = {
            header: tableHeader,
            rows: tableContent
        }
        this.output.addTable(table);
        this.output.addHR();

        this.output.addBR();
        this.output.addHR();

        const singleFieldsControl: FieldsControl = [
            {
                title: "Single FieldsControl",
                body: "Single field takes up full width, multiple fields are evenly spaced"
            }            
        ]
		this.output.addFields(singleFieldsControl);
        this.output.addHR();

        const multipleFieldsControl: FieldsControl = [
            {
                title: "Title A",
                body: "Multiple Fields A"
            },
            {
                title: "Title B",
                body: "Multiple Fields B"
            },
            {
                title: "Title C",
                body: "Multiple Fields C"
            }         
        ]
		this.output.addFields(multipleFieldsControl);
        this.output.addHR();

        let list: List = ["Ant", "Bat", "Cow", "Dog"];
        this.output.addOrderedList(list);
        this.output.addHR();

        list = ["Don't Stop Believin'", "Wheel in the Sky", "Anyway You Want It", "Seperate Ways"];
        this.output.addUnorderedList(list);
        this.output.addHR();

        this.output.addImage("https://anywhereteacher.com/sites/default/files/logo/anywhere-teacher-logo-apple-2021.png", 200, 200);
        this.output.addHR();

        this.output.addLink("Anywhere Teacher", "https://anywhereteacher.com");
        this.output.addHR();

        this.output.addButton("Click Me I Dare You", "https://anywhereteacher.com");
        this.output.addHR();

        let codeBlock: string = 
        `let nowDate = new Date();
let commandDate = new Date(command.timestamp);
let dateStr = commandDate.getMonth() + "/" + commandDate.getDay() + "/" + (commandDate.getFullYear().toString().slice(2));
let timeStr = (commandDate.getHours() % 12) + ":" + commandDate.getMinutes() + (commandDate.getHours() <= 12 ? " AM" : " PM");
let shouldShowDate = !(nowDate.getFullYear() === commandDate.getFullYear() && nowDate.getMonth() === commandDate.getMonth() && nowDate.getDate() === commandDate.getDate());`;
        this.output.addCodeBlock(codeBlock);
        this.output.addHR();

        this.output.addBlockQuote("Programming isn't about what you know; it's about what you can figure out.");
        this.output.addHR();

        const controlsObject = this.output.generateControls();
        window.szLogger.log("%c Controls Output Engine:", "background-color: pink; color: black", controlsObject);

        return controlsObject;
    }

	help () {
		this.output.setTitle("All Controls Command");
		this.output.addField("", 
			`Used to test the output of control entries for rendering.
			
			No parameters supported.`
		);
        return this.output.generateControls(false);
	}

	about (): IAbout {
		return {
			name: "All Controls",
			description: "Return all of the defined control entries for testing command processor renderer"
		}
	}
}

export default AllControlsCommand;
