import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useAppSelector } from "../hooks/hooks";

const bullet = (
	<Box
		component="span"
		sx={{ 
			display: "inline-block",
			mx: "2px",
			transform: "scale(0.8)" 
		}}
	>
	  •
	</Box>
);

const RightContent = () => {
	const {windowHeight, config} = useAppSelector((state) => state.root);
	/** 
	 * MUI components styled using emotion
	 */
	const StyledContent = styled("div")(() => ({
		height: windowHeight - config.toolbarHeight,
		position: "absolute",
		right: 0
	}));

	const StyledPaper = styled(Paper)(() => ({
		height: "100%",
		backgroundColor: "rgba(120, 120, 120, 0.05)",
		borderRadius: 0,
		padding: 5,
		justifyContent: "center",
		display: "flex"
	}));

	const StyledCard = styled(Card)(() => ({
		maxWidth: 210,
		marginBottom: 8
	}));

	return (
		<div>
			<StyledContent key="rightContent" >
				<StyledPaper variant="outlined" >
					<Stack direction="column">
						<StyledCard>
							<CardContent>
								<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
									Word of the Day
								</Typography>
								<Typography variant="h5" component="div">
									be{bullet}nev{bullet}o{bullet}lent
								</Typography>
								<Typography sx={{ mb: 1.5 }} color="text.secondary">
									adjective
								</Typography>
								<Typography variant="body2">
									well meaning and kindly.
									<br />
									{"\"a benevolent smile\""}
								</Typography>
							</CardContent>
							<CardActions>
								<Button size="small">Learn More</Button>
							</CardActions>
						</StyledCard>
						<StyledCard>
							<CardContent>
								<Typography>
									This is a second card with some other content
								</Typography>
							</CardContent>
						</StyledCard>
					</Stack>
				</StyledPaper>
			</StyledContent>
		</div>
	);
}

export default RightContent;
