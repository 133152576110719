import Toolbar from "@mui/material/Toolbar";
import { Outlet, useMatch } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
/**
 * AT2
 */
import MainDrawer from "../components/MainDrawer";
import RightContent from "../components/RightContent";
import { useEffect } from "react";
import TopToolbar from "../components/topToolbar/TopToolbar";
import { useAppDispatch } from "../hooks/hooks";
import { setWindowWidth, setWindowHeight } from "../slices/rootSlice";

import { Amplify, Auth } from "aws-amplify";
import { Authenticator, View, Image, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import config from "../config";
import { useTheme } from "@aws-amplify/ui-react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

Amplify.configure({
    Auth: {
        mandatorySignIn: false,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID
    }
});
Auth.configure({
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
});

const Root = () => {
    const dispatch = useAppDispatch();
	useEffect(() => {
		window.addEventListener("resize", () => {
			dispatch(setWindowWidth(window.innerWidth));
			dispatch(setWindowHeight(window.innerHeight));
		})
	})

	dispatch(setWindowWidth(window.innerWidth));
	dispatch(setWindowHeight(window.innerHeight));
    /**
     * ### Components Object
     * 
     * used to customize the Amplify Authentication UI
     */
    const components = {
        Header() {
            const { tokens } = useTheme();
            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Image
                        alt="anywhere teacher logo"
                        src="images/logo/anywhere-teacher-check.svg"
                        width="100px"
                        height="100px"
                    />
                    <Typography variant="h6">
                        AT 2.0 The Next Generation
                    </Typography>
                </View>
            );
        },

        Footer() {
            const { tokens } = useTheme();
            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Typography>
                    &copy; School Zone Publishing - All Rights Reserved
                    </Typography>
                </View>
            );
        },

        SignIn: {
             Footer() {
                const { toResetPassword } = useAuthenticator();
                return (
                    <View textAlign="center">
                        <Button
                            variant="contained"
                            onClick={toResetPassword}
                            sx={{m: 1}}
                        >
                            Reset Password
                        </Button>
                    </View>
                );
            },
        },
    }
    /**
     * ### Display Columnar Layout of Admin Interface
     * 
     * @param isRoot            if we are on the root view
     * @returns                 if root 3 column layout, otherwise 2 column layout
     */
    const displayColumns = (isRoot: boolean) => {
        if (isRoot) {
            /**
             * display 3 column layout
             */
            return(
                <>
                    <Grid xs={12} sm={2}>
                        <Toolbar />
                        <MainDrawer />
                    </Grid>
                    <Grid xs={12} sm={8}>
                        <Toolbar />
                        <div id="root-module">
                            <Outlet />
                        </div>
                    </Grid>
                    <Grid xs={12} sm={2}>
                        <Toolbar />
                        <RightContent />
                    </Grid>
                </>
            )
        } else {
            /**
             * display 2 column layout
             */
            return (
                <>
                    <Grid xs={12} sm={2}>
                        <Toolbar />
                        <MainDrawer />
                    </Grid>
                    <Grid xs={12} sm={10}>
                        <Toolbar />
                        <div id="root-module">
                            <Outlet />
                        </div>
                    </Grid>
                </>
            )
        }
    }

	const isRoot = useMatch("/") !== null;
    return (
        <Authenticator
            loginMechanisms={["email", "phone_number"]}
            components={components}
            variation="modal"
        >
            {() => (
                <div style={{overflow: "hidden"}}>
                    <TopToolbar />
                    <Grid container>
                        { displayColumns(isRoot) }
                    </Grid>
                </div>
            )}
        </Authenticator>
    ) 
}

export default Root;
