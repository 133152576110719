import React from "react";
import { useEffect, useRef, useMemo } from "react";
import Grid from "@mui/material/Grid";
/**
 * Phaser Test Bed
 */
import PhaserFWGame from "../classes/phaser/PhaserFWGame";
import PhaserFWConfig from "../classes/phaser/PhaserFWConfig";
import { setCanvas, clearCanvas, setPhaser } from "../slices/drawSlice";
import { useAppSelector, useAppDispatch } from "../hooks/hooks";

const Draw = () => {
    const dispatch = useAppDispatch();
    const isVisibleRef = useRef(useAppSelector((state) => state.draw.isVisible));
    const phaserConfig = useMemo(() => new PhaserFWConfig(), []);
	/**
	 * ! using the useEffect hook in this way is likely not the correct way - best practice is not to have 
	 * ! state changes in the useEffect because it may trigger re-render.  Even though we are debouncing
	 * ! the phaser object from getting re-rendered by using the isVisible state.  This however should allow
	 * ! us to use non-phaser controls (like the current HTML buttons) on the page without destroying the internal state
	 * ! of the Phaser object itself.  We are using useEffect to insure that the div container is rendered before
	 * ! mounting the Phaser object.
	 * 
	 * Additionally, we have implemented the cleanup function to clear the canvas, this is to handle React.StrictMode operation
	 * of mounting/unmounting/mounting again in dev mode.  With that being said - remounting a component is
	 * likely to destroy the phaser internal state.
	 */
	useEffect(() => {
		if (!isVisibleRef.current) {
			const element = document.getElementById(phaserConfig.config.parent as string);
			if (element && element.innerHTML.length <= 0) {
                const game = new PhaserFWGame(phaserConfig.config);
                const payload = {
                    phaser: game
                }
                dispatch(setPhaser(payload));
                dispatch(setCanvas());
                isVisibleRef.current = true;
			}
		}
        /**
         * Clean Up
         */
        return () => {
            dispatch(clearCanvas());
            isVisibleRef.current = false;
        }
	}, [isVisibleRef, phaserConfig, dispatch]);

	return (
		<React.Fragment>
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{ minHeight: "94vh" }}
			>
				<Grid item xs={3}>
					<div id={ phaserConfig.config.parent as string }></div>
				</Grid>   
			</Grid> 
		</React.Fragment>
	)
}

export default Draw;
