import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IColorState {
	isDark: boolean
}

const initialState: IColorState = {
	isDark: true
}

const colorModeSlice = createSlice({
	name: "colorMode",
	initialState,
	reducers: {
		toggleColorMode: (state) => {
			state.isDark = state.isDark ? false : true;
		},
		setColorMode: (state, action: PayloadAction<boolean> ) => {
			state.isDark = action.payload
		}
	}
});

export const { toggleColorMode, setColorMode } = colorModeSlice.actions;

export default colorModeSlice.reducer;
