export enum ControlList {
	Fields = "FIELDS",
	Table = "TABLE",
	OrderedList = "ORDEREDLIST",
	UnorderedList = "UNORDEREDLIST",
	Hr = "HR",
	Title = "TITLE",
	Color = "COLOR",
	Br = "BR",
	Image = "IMAGE",
	Link = "LINK",
	Button = "BUTTON",
	CodeBlock = "CODEBLOCK",
	BlockQuote = "BLOCKQUOTE"
}