import Typography from "@mui/material/Typography";

const TopToolbarVersion = () => {
	return (
		<Typography
			variant="subtitle2"
			sx={{display: { xs: "none", sm: "block" }, paddingLeft: 1 }}
		>
			2.0.0-dev.4
		</Typography>
	)
}

export default TopToolbarVersion;