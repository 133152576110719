import { useState } from "react"
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";

import { useAuthenticator } from "@aws-amplify/ui-react";

const TopToolbarUser = () => {
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorElement);
    const { user, signOut } = useAuthenticator((context) => [context.user]);

    window.szLogger.log("%c User Object", "background-color: pink; color: black", user);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        console.log("Open Menu")
        setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        console.log("Close Menu")
        setAnchorElement(null);
    };

    const handleProfile = () => {
        console.log("Click Profile")
    };

    const handleAccount = () => {
        console.log("Click Account")
    };

    const handleSettings = () => {
        console.log("Click Settings")
    };
    /**
     * ### Generates Aria Label Text of Notification Count
     * 
     * @param count             number of notifications
     * @returns                 readable text
     */
    const notificationsLabel = (count: number) => {
        if (count === 0) {
          return 'no notifications';
        }
        if (count > 99) {
          return 'more than 99 notifications';
        }
        return `${count} notifications`;
    }
    /**
     * TODO: add notification count from profile when implemented
     */
    const numberOfMessages = 1;

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <Typography>
                    {user && user.attributes ? user.attributes.email : "Mystery Guest"}
                </Typography>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        aria-label={notificationsLabel(numberOfMessages)}
                    >
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            badgeContent={numberOfMessages}
                            color="error"
                        >
                            <AccountCircleIcon style={{width: 30, height: 30}}/>
                        </Badge>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorElement}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: `""`,
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem onClick={handleProfile}>
                    <Avatar /> Profile
                </MenuItem>
                <MenuItem onClick={handleAccount}>
                    <Avatar /> My account
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleSettings}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                {
                    user
                    ? 
                        <MenuItem onClick={signOut}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    :
                        null
                }
 
            </Menu>
        </>
    )
}

export default TopToolbarUser;
