import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICommandProcessorState, IDisplay, InputList } from "../types/common/command";

const initialCommand: IDisplay = {
	user: "",
	created: 0,
	command: "",
	params: [],
	output: []
}

const initialState: ICommandProcessorState = {
	command: initialCommand,
	commands: undefined,
	shouldDisplayJumpToBottom: false,
	isFetching: false
}

const commandProcessSlice = createSlice({
	name: "command",
	initialState,
	reducers: {
		prependCommand: (state, action: PayloadAction<IDisplay>) => {
			if(state.commands === undefined){
				state.commands = [];
			}
			// Add to the front of the commands array
			state.commands.unshift(action.payload);
		},
		appendCommand: (state, action: PayloadAction<IDisplay>) => {
			if(state.commands === undefined){
				state.commands = [];
			}
			// Add to the end of the commands array
			state.commands.push(action.payload);
		},
		setCommands: (state, action: PayloadAction<InputList>) => {
			state.commands = action.payload;
		},
		setShouldDisplayJumpToBottom: (state, action: PayloadAction<boolean>) => {
			state.shouldDisplayJumpToBottom = action.payload;
		},
		setIsFetching: (state, action: PayloadAction<boolean>) => {
			state.isFetching = action.payload;
		}
	}
});

export const { prependCommand, appendCommand, setCommands, setShouldDisplayJumpToBottom, setIsFetching } = commandProcessSlice.actions;

export default commandProcessSlice.reducer;
