import TemplateCommand from "./TemplateCommand";
import UnknownCommand from "./UnknownCommand";
import AllControlsCommand from "./AllControlsCommand";
import { CommandParameters } from "../../types/types";
/**
 * #### Command Factory
 * 
 * Standard factory pattern implementation to support simple command processing.  
 *   
 * **CommandParameters**  
 * are structured as an array of strings where, at least, the first parameter [index 0] describes the command
 * that is going to be processed.  Multiple word commands can be implemented by adding additional hierarchy of
 * switches within a commands case structure.
 *
 * @class CommandFactory
 */
class CommandFactory {
	/**
	 * #### Get Command from Factory
	 *
	 * @param CommandParameters 						base 0 array of a command string
	 * @returns 										instantianted command object
	 */
	public getCommand = (commandParameters: CommandParameters): any => {
		if (commandParameters.length <= 0) return new UnknownCommand(commandParameters);

        switch (commandParameters[0]) {
            case "all":
                return new AllControlsCommand(commandParameters);
			case "template":
			case "t":
                return new TemplateCommand(commandParameters);
            default:
                return new UnknownCommand(commandParameters);
        }
    }

	// public commands = (): Array<string> => {
	// 	return this.commands;
	// }
}

export default CommandFactory;
