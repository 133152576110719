/**
 * ## Command Processing
 * 
 * @param outputEngine  
 * AbstractCommand will use a simple factory pattern to instantiate the desired output
 * class for use by the command.  
 *   
 * | ID | Description |
 * | --- | --- |
 * | control (default) | output support for Command Processor |
 * | md | output support for CommonMark implementation of Markdown |
 *
 * @category Abstract Class
 * @abstract
 * @class AbstractCommand
 * @module Abstract Command Factory
 */
import { CommandParameters, IAbout, IControl } from "../../types/types";
import ControlOutput from "../ControlOutput";

abstract class AbstractCommand {
    private _params: CommandParameters;
	private _outputEngine: string;
	private _output: ControlOutput;

    constructor (params: CommandParameters, outputEngine: string = "control") {
        this._params = params;
		this._outputEngine = outputEngine;
		switch (outputEngine) {
            case "control":
                this._output = new ControlOutput();
                break;
			default:
				this._outputEngine = "control";
				this._output = new ControlOutput();
				break;
		}
    }

    public get params (): CommandParameters {
        return this._params;
    }

	public get outputEngine (): string {
		return this._outputEngine;
	}

	public get output (): ControlOutput {
		return this._output;
	}
	/**
	 * #### Command Executor
	 *
	 * @abstract
	 * @returns {*} 
	 */
	abstract execute (): IControl;
	/**
	 * #### Command Help
	 *
	 * @abstract
	 * @returns {*} 
	 */
	abstract help (): IControl;
	/**
	 * #### Command About
	 *
	 * @abstract
	 * @returns {*} 
	 */
	abstract about (): IAbout;
}

export default AbstractCommand;
