/**
 * ## Logger Class
 * 
 * @category Class
 * @module Logger
 */
class SZLogger {
    /**
     * typically provided using window.location.hostname but can be any domain string
     */
	private _activeDomain: string;
    /**
     * overrides process.eve.NODE_ENV setting and restricts logging only based on domain
     */
	private _overrideNodeEnv: boolean;

	/**
	 * ### Initialize the SZLogger class
	 * 
	 * @param hostname typically provided using window.location.hostname but can be any domain string
     * @param override overrides process.eve.NODE_ENV setting and restricts logging only based on domain
	 */
	constructor (hostname: string, override = false) {
		this._activeDomain = hostname;
		this._overrideNodeEnv = override;
	}

	/**
	 * ### Determine if the hostname represents a SZ testing domain
	 * + localhost
	 * + beta.anywhereteacher.com
	 * + dev.mini.anywhereteacher.com
	 * 
	 * @returns true if the hostname matches the domains
	 */
	private isDevelopmentHost = (): boolean => {
		return (
			this._activeDomain === "localhost"
			|| this._activeDomain === "beta.anywhereteacher.com"
			|| this._activeDomain === "dev.mini.anywhereteacher.com"
		);
	}

	/**
	 * ### Allow logging
	 * 
	 * Allow for overriding of the process.env.NODE_ENV state and choose
	 * to log based on the domain only.
	 *
	 * @private
	 */
	private allowLogging = (): boolean => {
		if (this._overrideNodeEnv) {
			return this.isDevelopmentHost();
		} else {
			return process.env.NODE_ENV !== "production"
		}
	}

	/**
	 * ### Outputs a console error
	 * 
	 * Errors are never filtered
	 * 
	 * @param args a variable number of items to be output
	 */
	public error = (...args: any): void => {
		console.error(...args);
	}

	/**
	 * ### Outputs a console log 
	 * 
	 * Verbose will output console.log additionally when using SZ testing domain
	 * 
	 * @param args a variable number of items to be output
	 */
	public verbose = (...args: any): void => {
		if (this.allowLogging()) {
			console.log(...args);
		}
	}

	/**
	 * ### Outputs a console info
	 * 
	 * Verbose will output console.info additionally when using SZ testing domain
	 * 
	 * @param args a variable number of items to be output
	 */
	public info = (...args: any): void => {
		if (this.allowLogging()) {
			console.info(...args);
		}
	}

	/**
	 * ### Outputs a console log
	 * 
	 * @param args a variable number of items to be output
	 */
	public log = (...args: any): void => {
		if (this.allowLogging()) {
			console.log(...args);
		}
	}

	/**
	 * ### Outputs a console warn
	 * 
	 * @param args a variable number of items to be output
	 */
	public warn = (...args: any): void => {
		if (this.allowLogging()) {
			console.warn(...args);
		}
	}

	/**
	 * ### Outputs a console trace
	 * 
	 * @param args a variable number of items to be output
	 */
	public trace = (...args: any): void => {
		if (this.allowLogging()) {
			console.trace(...args);
		}
	}

	/**
	 * ### Outputs a console group
	 * 
	 * Groups are delimited by a subsequent end()
	 * 
	 * @param arg a title to be output as the group heading
	 */
	public group = (arg: string): void => {
		if (this.allowLogging()) {
			console.group(arg);
		}
	}

	/**
	 * ### Outputs a console groupCollapsed
	 * 
	 * Groups are delimited by a subsequent end()
	 * 
	 * @param args a title, and option color to be output as the group heading
	 */
	public collapse = (...args: any): void => {
		if (this.allowLogging()) {
			console.log(...args);
			console.groupCollapsed("Click to Expand");
		}
	}

	/**
	 * ### Outputs a console groupEnd
	 */
	public end = (...args: any): void => {
		if (this.allowLogging()) {
			console.groupEnd();
			if (args && args.length > 0) {
				console.log(...args);
			}
		}
	}
}

export default SZLogger;
