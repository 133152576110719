import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import CommandFactory from "../classes/commands/CommandFactory";

const CommandRoute = () => {
	/**
	 * Generate and execute a random command on page refresh
	 */
	const commandList = ["test", "dog", "cat"];
	const index = Math.floor(Math.random() * commandList.length);
	const params = [
		commandList[index]
	];

	const factory = new CommandFactory();
	const command = factory.getCommand(params);

	return (
		<Box>
			<Grid style={{ marginLeft: "20px" }}>
				<Grid item xs={3}>
					<ReactMarkdown remarkPlugins={[remarkGfm]}>{command.execute()}</ReactMarkdown>
				</Grid>   
			</Grid> 					
		</Box>
	);
}

export default CommandRoute;
