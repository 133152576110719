import { PaletteMode } from "@mui/material"
/** 
 * Anywhere Teacher 2.0 Theme - Admin Dark Theme
 */
const AT2ThemeDark = {
	palette: {
		mode: "dark" as PaletteMode
	},
	typography: {
		fontSize: 12,
		subtitle2: {
			fontSize: 10
		}
	}
};

export default AT2ThemeDark;
