import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISnackbarState } from "../types/types";

const initialState: ISnackbarState = {
	isOpen: false,
	severity: "info",
	message: ""
}

const snackbarSlice = createSlice({
	name: "snackbar",
	initialState,
	reducers: {
		setOpen: (state, action: PayloadAction<boolean>) => {
			state.isOpen = action.payload;
		},
        setSeverity: (state, action: PayloadAction<AlertColor>) => {
			state.severity = action.payload;
		},
        setMessage: (state, action: PayloadAction<string>) => {
			state.message = action.payload;
		}
	}
});

export const { setOpen, setSeverity, setMessage } = snackbarSlice.actions;

export default snackbarSlice.reducer;
