import React from "react";
import Draw from "../components/Draw";

const PhaserRoute = () => {

	return (
		<React.Fragment>		
			<Draw />
		</React.Fragment>
	);
}

export default PhaserRoute;
